
import React, { createContext, useCallback, useContext, useEffect, useState } from 'react'

import { User } from './payload-types' 
import { authrest } from './Api'
import { AuthContext, Create, ForgotPassword, Login, Logout, ResetPassword } from './types'

const Context = createContext({} as AuthContext)
const baseUri = `${process.env.REACT_APP_PUBLIC_CMS_URL}/api/customers` 
const baseUriAdmins = `${process.env.REACT_APP_PUBLIC_CMS_URL}/api/users` 
export const AuthProvider = ({
  children,
  api = 'rest',
} : { children: React.ReactNode; api?: 'rest' | 'gql' }) => {
  const [user, setUser] = useState<User | null>()
  const [token, setToken] = useState<string | null>()
  const [exp, setExp] = useState<number | null>()
  const [authError, setAuthError] = useState("")
  const [isLoaded, setIsLoaded] = useState(false)

  const create = useCallback<Create>(
    async args => { 
        const r = await authrest(`${baseUri}`, args)
        if(r?.user) { 
          setUser(r.user)
          setToken(r.token)
          setExp(r.exp)
        }
        throw Error('Could not create user')
    }, 
    [],
  )
 
  const login = useCallback<Login>(
    async args => { 
      const r = await authrest(`${baseUri}/login`, args)
      if(!r?.user) {
        throw Error('Could not login') 
      }
      setUser(r.user)
      setToken(r.token)
      setExp(r.exp)
      return r.user 
    },
    [],
  )

  const refresh = useCallback(
    async ()=> { 
      const r = await authrest(`${baseUri}/refresh-token` )
      if(!r?.user) {
        throw Error('Could not refresh user') 
      }
      setUser(r.user)
      setToken(r.token)
      setExp(r.exp)
      return r.user 
    },
    [],
  )

  const logout = useCallback<Logout>(async () => {
 
      await authrest(`${baseUri}/logout`)
      setUser(null)
      setToken(null)
      setExp(null)
      return
 
 
  }, [])

  // On mount, get user and set
  useEffect(() => {
    if(user) {
      if(exp && exp - 600 < (Date.now() / 1000)) {
        refresh()
      }
      return
    }
    const fetchMe = async () => {
      const passed = [baseUri, baseUriAdmins].map(async uri => {
        const r = await authrest(
          `${uri}/me`,
          {},
          {
            method: 'GET',  
          },
        ) 
        
        return r
      })
      
      const results = await Promise.all(passed) 
      const passedRequest = results.find(r => r?.user && r?.token)
      if(passedRequest) {       console.log(passedRequest)

        setUser(passedRequest.user) 
        setToken(passedRequest.token) 
      }
      setIsLoaded(true)
    } 

    fetchMe()
  }, [api, user, refresh, exp])

  const forgotPassword = useCallback<ForgotPassword>(
    async args => {
      
        const r = await authrest(
          `${baseUri}/forgot-password`,
          args,
        )
        if(r?.user) { 
          setUser(r.user)
          setToken(r.token)
          return r.user 
        } 
        throw Error('could not reset password')
    },
    [],
  )

  const resetPassword = useCallback<ResetPassword>(
    async args => {
 
        const r = await authrest(`${baseUri}/reset-password`, args)
        if(r?.user) {

          setUser(r.user)
          setToken(r.token)
          return r.user
        }
     
        throw Error('failed to reset')
    },
    [],
  )

  return (
    <Context.Provider
      value={{
        user,
        isLoaded,
        authError,
        token,
        setUser,
        login,
        logout,
        create,
        resetPassword,
        forgotPassword,
      }}
    >
      {children}
    </Context.Provider>
  )
}

type UseAuth<T = User> = () => AuthContext // eslint-disable-line no-unused-vars

export const useAuth: UseAuth = () => useContext(Context)