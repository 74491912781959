import { useLocation, useNavigate } from "react-router-dom";
  
import { ReactNode, useEffect } from "react"; 
import { useAuth } from "./AuthProvider";
import ErrorBox from "./ErrorBox";
import LoaderMessage from "./LoaderMessage";

export default function AuthWrapper({ children }: { children: ReactNode }) {
    const loc = useLocation()
    const params = new URLSearchParams(loc.search);
    const nav = useNavigate()
    const { user, isLoaded, authError } = useAuth(); 

    if (authError) {
        return <ErrorBox>{params.get("error_description")}</ErrorBox>
    }

    if (!isLoaded) {
        return <LoaderMessage></LoaderMessage>
    }

    if (!user && isLoaded) {
        nav('/login');
        return <LoaderMessage></LoaderMessage>
    }

    return <>{children}</>
}