/* This example requires Tailwind CSS v2.0+ */
import { Children, Fragment } from 'react'
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { BellIcon, MenuIcon, XIcon } from '@heroicons/react/outline'
import { Outlet, Link, NavLink } from "react-router-dom";
import { useTranslation, initReactI18next } from "react-i18next";
import { useAuth } from './AuthProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHatHard, faIdCard, faUser } from '@fortawesome/free-solid-svg-icons';

const navigation = [
  { translationKey: 'home', href: '/' },
]
document.body.classList.add('h-full')
document.title = 'Tone Scaping'

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

export default function Template() {
  const { t } = useTranslation();
  const { user } = useAuth();
  return (
    <>
      <div className="min-h-full">
        <Disclosure as="nav" className="border-b-2">
          <>
            <div className="max-w-7xl mx-auto px-2 sm:px-4 lg:px-8">
              <div className="flex items-center justify-between h-16">
                <div className="flex items-center">
                  <div className="flex-shrink-0">
                    <NavLink to="/" key="logolink" >
                      <img
                        className="h-12"
                        src="/tslogo3.png"
                        alt="Workflow"
                      /></NavLink>
                  </div>
                  <div className="hidden md:block">
                    <div className="ml-10 flex items-baseline space-x-4">
                      {navigation.map((item) => (
                        <NavLink
                          to={item.href}
                          key={item.translationKey}

                          className={
                            ({ isActive }) => {
                              return classNames(
                                isActive ? 'bg-red-600 text-white' : 'text-gray-600 hover:bg-red-600 hover:text-white',
                                'px-3 py-2 rounded-md text-sm font-medium'
                              )
                            }
                          }
                        >
                          {t(item.translationKey)}
                        </NavLink>
                      ))}
                    </div>
                  </div>
                </div>
                <div>{user ? <>
                  <span className='text-xs text-gray-500 px-3 py-2 bg-gray-50 rounded'><FontAwesomeIcon className='text-gray-400 mr-1' icon={faUser}></FontAwesomeIcon> {user.email}</span>
                  {user.role?.includes('admin') ? <>
                     <span className='text-xs text-gray-500 px-3 py-2 bg-gray-50 ml-3'><FontAwesomeIcon className='text-gray-400 mr-1' icon={faIdCard}></FontAwesomeIcon> Administrator </span>
                  </> : null}
                </> : null}</div>
              </div>
            </div>


          </>
        </Disclosure>

        <main>
          <div className="max-w-7xl mx-auto px-2 py-6 sm:px-6 lg:px-8">
            <Outlet />
          </div>
        </main>
      </div>
    </>
  )
}