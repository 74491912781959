import React from "react";
import Template from "./Template"; 
import { BrowserRouter, Routes, Route } from "react-router-dom";
import NotFound from "./NotFound.js";
import MeasurementList from "./MeasurementList"; 
import Measurement from "./Measurement";

import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from "react-i18next";
import { AuthProvider } from "./AuthProvider";
import { Login } from "./Login";
import AuthWrapper from "./AuthWrapper";

i18next.use(LanguageDetector).use(initReactI18next).init({ 
  debug: true,
  fallbackLng: 'en',
  resources: {
    en: {
      translation: {
        "home":  "Home",
        "measurements": "Measurements",
        "search_compare": "Search to Compare",
        "loading": "Loading",
        "scan": "Scan",
        "select": "Select",     
        'brightness': 'Brightness',
        'loudness':'Loudness',
        'sustain':'Sustain',
        'warmth': 'Warmth',
        'presence': 'Presence',
        'dynamics': 'Dynamics',
        'harmonics': 'Overtones richness',
        'sound_pressure_level': 'Sound Pressure Level - Response', 
        'sound attributes': 'Sound Attributes',
        'error': 'error',
        'not found': 'not found',
        'year': 'Year',
        'store_link': 'Store Link',
        'scan_qr': 'Compare QR Code',
        'or': 'or',
        'creds_error': 'There was an error with the credentials provided. Please try again.',
      }
    },   
    it: {
      translation: {
        "home":  "Home",
        "measurements": "Misure",
        "search_compare": "Compara modello",
        "loading": "Caricamento",
        "scan": "Scan",
        "select": "Seleziona",      
        'brightness': 'Brightness', 
        'loudness':'Loudness',
        'sustain':'Sustain',
        'warmth': 'Warmth',
        'presence': 'Presence',
        'dynamics': 'Dynamics',
        'harmonics': 'Overtones richness',
        'sound attributes': 'Sound Attributes',
        'sound_pressure_level': 'Sound Pressure Level - Response',
        'error': 'errore',
        'not found': 'non trovato',
        'year': 'Anno',
        'store_link': 'Link al negozio',
        'scan_qr': 'Compara QR Code',
        'or': 'oppure',
        'creds_error': 'Le credenziali fornite sono errate, per favore riprova',

      }
    }
  }
});
export default function App() {


  return (
    <><AuthProvider> 
      <BrowserRouter>
      <Routes>
        <Route path="/" element={<Template />}>
              <Route index element={<AuthWrapper><MeasurementList /></AuthWrapper>} />
              <Route path="*" element={<NotFound />} />
              <Route path="measurement" element={<AuthWrapper><MeasurementList /></AuthWrapper>} />
              <Route path="measurement/:name" element={<AuthWrapper><Measurement /></AuthWrapper>} /> 
             

        </Route>
        <Route path="login" element={<Login />} />
      </Routes>
        </BrowserRouter>
        </AuthProvider>
    </>
  );
}