 
import { Link } from "react-router-dom"; 
import { useEffect, useState } from "react";
import { Measurement } from "./payload-types";
import { getUniqueCode } from "./Measurement";



export default function MeasurementList() {

  const [loadedList, setLoadedList] = useState<{docs: Measurement[]}>()
  const [loading, setLoading] = useState(false)
  const [loaded, setLoaded] = useState(false)
  const [error, setError] = useState("")
  useEffect(() => {
    // validate the Payload token
    async function load() {
      setLoading(true)
      const userReq = await fetch(`${process.env.REACT_APP_PUBLIC_CMS_URL}/api/measurement`,   {
        credentials: "include", 
      })   
      setLoading(false)
      setLoaded(true)
      if (!userReq.ok) {
        console.log(userReq)
        return
      }
      try { 
        const userRes = await userReq.json() 
        console.log(userRes)
        setLoadedList(userRes)
      }catch(e) {
        console.log(e) 
      } 
    }
    if (loaded) {
      return
    }
    try {

      load()
    } catch (e) {
      setError(`${e}`)
    }
  }, [loadedList, loaded])

  const hasList = loadedList && 'docs' in loadedList && loadedList.docs.length
  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error : {error}</p>;
  if (!hasList) return <p>No measurements</p>;

  return <div className="bg-white">
    <div className="max-w-2xl mx-auto py-0 px-2 sm:py-2 sm:px-2 lg:max-w-7xl lg:px-2">

      <div className="mt-6 grid grid-col gap-y-10 gap-x-6 sm:grid-cols-2 lg:grid-cols-3">
        {loadedList.docs.map((product) => (
          <div key={product.id} className="group relative py-2 rounded  border border-gray-200">
          <Link to={"measurement/" + getUniqueCode(product)}> 
            <div className="w-full h-80 aspect-w-1 aspect-h-1 rounded-md overflow-hidden group-hover:opacity-75 lg:h-80 lg:aspect-none">
            
              <img
                src={`${process.env.REACT_APP_PUBLIC_CMS_URL}${typeof product.images[0].image !== 'string' ? product.images[0].image?.sizes?.thumbnail?.url : ''}`}
                alt={""}
                className="h-full object-center mx-auto lg:h-full"
              /> 
            </div>
            <div className="mt-4 ">
              <div>
                <h3 className="text-sm text-center text-gray-700">
                  
                    <span className="text-xl px-3 py-2">{product.brand} {product.model}</span>
                
                </h3>
              </div>
            </div></Link>
          </div>  
        ))}
      </div>
    </div>
  </div>
}