 
import { useTranslation } from "react-i18next";  
  
import React, { useCallback, useRef } from 'react' 
 
import { useAuth } from './AuthProvider'
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Message } from "./Message";
import { Input } from "./Input";
import { t } from "i18next";
import ErrorBox from "./ErrorBox";
 

type FormData = {
  email: string
  password: string
}

export const Login: React.FC = () => {
  let [searchParams, setSearchParams] = useSearchParams(); 
  const allParams = searchParams.toString() ? `?${searchParams.toString()}` : ''

  const redirect = useRef(searchParams.get('redirect'))
  const { login, user } = useAuth() 
  const nav = useNavigate()
  const [error, setError] = React.useState<string | null>(null)

  const {
    register,
    handleSubmit,
    formState: { errors, isLoading },
  } = useForm<FormData>({
    defaultValues: {
      email: '',
      password: '',
    },
  })

  const onSubmit = useCallback(
    async (data: FormData) => {
      try {
        await login(data)
        if (redirect?.current) {
          nav( redirect.current )
        }
        else nav('/')
      } catch (_) {
        setError(t('creds_error'))
      }
    },
    [login, nav],
  )

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={'m-auto mt-[15%] w-2/6'}>
       <img
                      className="h-32 m-auto mb-12 mt-12"
                      src="/tslogo1.png"
                      alt="Workflow"
                    />
      <ErrorBox >{error}</ErrorBox>
      <Input 
        name="email"
        label="Email Address"
        required
        register={register} 
        error={errors.email}
        type="email"
      />
      <Input
        name="password"
        type="password"
        label="Password"
        required
        register={register}
        error={errors.password}
      />
      <button
        type="submit"
        disabled={isLoading}
        className={'mt-4 w-full text-xl px-3 py-2 rounded bg-slate-900 text-white'}  
      >{isLoading ? 'Processing' : 'Login'}</button>
      <div>
        {/* <Link to={`/create-account${allParams}`}>Create an account</Link>
        <br />
        <Link to={`/recover-password${allParams}`}>Recover your password</Link> */}
      </div>
    </form>
  )
}
