import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { ReactNode } from "react";

const LoaderMessage = ({ children }: { children?: ReactNode }) => {

    return <div className="bg-blue-100 border border-blue-400 text-blue-700 px-4 py-3 rounded relative" role="alert">
        <span className="inline-block sm:inline">{children ?? 'Loading...'}  <FontAwesomeIcon className="inline-block animate-spin h-5 w-5 mr-3" size="sm" icon={faSpinner} /> </span>
    </div>
}

export default LoaderMessage;