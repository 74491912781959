import Plot from "react-plotly.js";
import { MeasurementData } from "./Measurement";
import { useTranslation } from "react-i18next";
import { PlotData } from "plotly.js";

export default function MeasurementRadarChart({
  current,
  compare,
}: {
  current: MeasurementData;
  compare: MeasurementData[];
}) {
  const { t } = useTranslation();
  const data = [current, ...compare].map((c) => {
    return {
      type: "scatterpolar",
      r: Object.values(c.attributes ?? []),
      theta: Object.keys(c.attributes ?? []).map((k) => {
        return t(k);
      }),
      fill: "toself",
      name: c.name,
    };
  }) as PlotData[];
  const allValues = [current, ...compare].reduce((res, cur) => {
    res.push(...Object.values(cur.attributes ?? []));
    return res;
  }, [] as number[]);
  return (
    <div className="md:px-4 md:py-2 mt-4 md:bg-neutral-100">
      <div className="max-w-7xl mt-2 ">
        <h1 className="text-2xl font-bold text-gray-900">
          {t("sound attributes")}
        </h1>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2">
        <div className="mt-2 mr-6">
          <Plot
            data={data} 
            useResizeHandler={true}
            style={{ width: "100%", height: "100%" }}
            config={{ displaylogo: false, watermark: false, showLink: false }}
            layout={{
              margin: { l: 0, t: 50, b: 50, r: 20 },
              dragmode: "select",
              polar: {
                radialaxis: {
                  visible: true,
                  scaleratio: 1,
                  range: [0, Math.max(...allValues)],
                },
              },
            }}
          ></Plot>
        </div>

        <div className=" mt-8 ">
          {Object.keys(current.attributes ?? {}).map((k,i) => {
            return ( <div key={i}>
                  <h5 className="font-bold">{t(k)}:</h5>
               
                <p>Explanation of the sound attribute </p> 
                </div> 
         
            );
          })}
        </div>
      </div>
    </div>
  );

  // const transformed = transformData(props.current.attributes.data)
  // let dataset = [{
  //     id: props.current.attributes.model,
  //     data: transformed
  // }]
  // if (props.compare && props.compare.length > 0) {
  //     props.compare.forEach((el) => {
  //         dataset.push({
  //             id: el.attributes.model,
  //             data: transformData(el.attributes.data)
  //         })
  //     })
  // }

  // const dataByCat = dataset.reduce((res, cur) => {
  //     res = res || {}

  //     const modelData = cur.data.reduce((acc, val) => {
  //         acc.bass = acc.bass || 0;
  //         acc.mids = acc.mids || 0;
  //         acc.highs = acc.highs || 0;

  //         if (val.x < 250) {
  //             acc.bass += val.y
  //         }
  //         if (val.x < 500) {
  //             acc.mids += val.y
  //         }
  //         if (val.x < 800) {
  //             acc.highs += val.y
  //         }
  //         return acc;
  //     }, {});
  //     res[cur.id] = modelData
  //     return res
  // }, [])

  // const categories = ["bass", "mids", "highs"]
  // const radarData = []
  // categories.forEach((cat) => {
  //     const catData = {}
  //     catData["category"] = cat
  //     Object.keys(dataByCat).forEach((model) => {
  //         catData[model] = dataByCat[model][cat]
  //     })
  //     radarData.push(catData)
  // })
  // return RadarChart(radarData, Object.keys(dataByCat), "category")
}

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.
// const RadarChart = (data, seriesNames, indexCol) => (
//     <ResponsiveRadar
//         data={data}
//         keys={seriesNames}
//         indexBy={indexCol}
//         valueFormat=">-.2f"
//         margin={{ top: 70, right: 80, bottom: 40, left: 80 }}
//         borderColor={{ from: 'color' }}
//         gridLabelOffset={36}
//         dotSize={10}
//         dotColor={{ theme: 'background' }}
//         dotBorderWidth={2}
//         colors={{ scheme: 'nivo' }}
//         blendMode="multiply"
//         motionConfig="wobbly"
//         legends={[
//             {
//                 anchor: 'top-left',
//                 direction: 'column',
//                 translateX: -50,
//                 translateY: -40,
//                 itemWidth: 80,
//                 itemHeight: 20,
//                 itemTextColor: '#999',
//                 symbolSize: 12,
//                 symbolShape: 'circle',
//                 effects: [
//                     {
//                         on: 'hover',
//                         style: {
//                             itemTextColor: '#000'
//                         }
//                     }
//                 ]
//             }
//         ]}
//     />
// )
// function transformData(data) {
//     return data.map((el) => {
//         return { x: el.freq, y: parseInt(el.db) + 90 }
//     })
// }
