

import React, { ReactNode } from "react";

const ErrorBox = ({ children }: { children?: ReactNode }) => {

    return children ? <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 mb-3 rounded relative" role="alert">
        <span className="block sm:inline">{children}</span>
    </div> : null
}

export default ErrorBox;

