import { User } from "./payload-types"

export const authrest = async (
    url: string,
    args?: any, // eslint-disable-line @typescript-eslint/no-explicit-any
    options?: RequestInit,
  ): Promise<{user: User, token: string, exp: number} | null | undefined> => {
    const method = options?.method || 'POST'
  
    try {
      const res = await fetch(url, {
        method,
        ...(method === 'POST' ? { body: JSON.stringify(args) } : {}),
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          ...options?.headers,
        },
        ...options,
      })
  
      const { errors, user, token, exp } = await res.json()
  
      if (errors) {
        throw new Error(errors[0].message)
      }
  
      if (res.ok) {
        return {user, token, exp}
      }
    } catch (e: unknown) {
      throw new Error(e as string)
    }
  }