import { faAngleLeft, faAngleRight, faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { TECarousel, TECarouselItem } from "tw-elements-react";

export default function Slideshow({imgUris}: {imgUris: string[]}): JSX.Element {
  const items =   imgUris.map((el, i) => {
   
    return <TECarouselItem  
    key={i}
    itemID={i+1}  
    className="relative float-left -mr-[100%] hidden w-full transition-transform duration-[600ms] ease-in-out motion-reduce:transition-none"
  >
    <img
      src={el}
      className="block h-full"
      alt=""
    />
    {/* <div className="absolute inset-x-[15%] bottom-5 hidden py-5 text-center text-white md:block">
      <h5 className="text-xl">First slide label</h5>
      <p>
        Some representative placeholder content for the first slide.
      </p>
    </div> */}
  </TECarouselItem> 
  }) 
  return (
    <>
      <TECarousel interval={false} 
      nextBtnIcon={items.length > 1 ? <><FontAwesomeIcon className="text-gray-500 !h-6" icon={faAngleRight}></FontAwesomeIcon></> : undefined} 
      prevBtnIcon={items.length > 1 ? <><FontAwesomeIcon className="text-gray-500 !h-6" icon={faAngleLeft}></FontAwesomeIcon></> : undefined} 

      showControls   ride="carousel">
        <div className="relative w-full overflow-hidden after:clear-both after:block after:content-['']"> 
          { items}
        </div>
      </TECarousel>
    </>
  );
}